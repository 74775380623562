import style from './homefooter.module.scss'
import { FooterMenu } from '../footer-menu'
import { MenuState } from '~/stores/menu'
import { Plus } from '../../icons/plus'
import { useLayoutEffect, useState } from 'react'

export const HomeFooter = () => {
  const menuState = MenuState.useContainer()

  const nocastemi = {
    title:
      "We know it's not always possible for you to order your favourite high-end gadget by shelling out the entire processing fee. Hence, we have the perfect solution to make it possible for everyone to access premium gadgets! No Cost EMI. With our No Cost EMI plan, you'll be able to shop most of our gadgets without selling an arm and a leg. You can order from Select Smartphones, Laptops, Smart TVs, Smart Devices, Wearables, Personal & Health Care Devices, etc. Have a Dream Premium Gadget? Well, it's no more a Dream! Go Get that Gadget on No Cost EMI Now! Terms and Conditions Apply.",
  }
  const debitcardemi = {
    title:
      "It comes as no surprise more people hold debit cards than credit cards. Well, it's understandable, considering how easy it is to manage a debit card confidently, without extra payments or hassles. Since our main aim is to ease any difficulties while you purchase your favourite gadgets, we have now added Debit Card EMI Options on almost most of our products. Just look up your desired gadget to check if it has a Debit Card EMI Payment Option for the respective Bank Card you use. And then, Tada! Time to flaunt your new gadget around happily. Terms and Conditions Apply.",
  }
  const exchange = {
    title:
      "It's better to be smart than to be sorry. You can now Exchange your old Smartphone for a Newer Smartphone with an Instant Discount from Poorvika. Our gadget experts give your old Smartphone a thumbs-up after checking if it is in proper working condition and then they provide an exchange value. If you find your favourite New Smartphone eligible for Exchange, you can order by clicking on the " +
      'Buy with Exchange' +
      " option. That's all it takes to Exchange your old Smartphone at a cheaper rate. Terms and Conditions Apply.",
  }
  const [toggleFooter, setToggleFooter] = useState(false)
  const [IsSmallFooter, setIsSmallFooter] = useState(false)
  useLayoutEffect(() => {
    setIsSmallFooter(window.innerWidth <= 736)
    if (window.innerWidth > 736) {
      setToggleFooter(true)
    } else {
      setToggleFooter(false)
    }
    window.onresize = (_e) => {
      setIsSmallFooter(window.innerWidth <= 736)
      if (window.innerWidth > 736) {
        setToggleFooter(true)
      } else {
        setToggleFooter(false)
      }
    }
  }, [])
  return (
    <footer
      className={style.footer}
      onMouseEnter={() => menuState.getFullData()}
    >
      <div>
        <div className={`p-6 ${style.common_footer}`}>
          <div className={`${style.show_hide_footer}`}>
            {IsSmallFooter && (
              <button
                onClick={() => {
                  menuState.getFullData(), setToggleFooter(!toggleFooter)
                }}
              >
                Shop more by Category{' '}
                <span>
                  <Plus></Plus>
                </span>
              </button>
            )}
            <div
              className={`${
                IsSmallFooter
                  ? toggleFooter
                    ? style.show_footer_categories
                    : style.hide_footer_categories
                  : ''
              }`}
            >
              {toggleFooter && <FooterMenu data={menuState} />}
            </div>
          </div>
          <div className={style.footer_container}>
            <div className="mt-12">
              <h6 className={` inline-block	${style.footer_title}`}>
                No Cost EMI
              </h6>
              <p className={style.nocostemi}>{nocastemi.title}</p>
            </div>
            <div>
              <h6 className={`inline-block	${style.footer_title}`}>
                EMI on Debit Cards
              </h6>
              <p className={style.nocostemi}>{debitcardemi.title}</p>
            </div>
            <div className={`${style.footer_space}`}>
              <h6 className={`inline-block	${style.footer_title}`}>
                Mobile Exchange Offers
              </h6>
              <p className={style.nocostemi}>{exchange.title}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default HomeFooter

import Link from 'next/link'
import { FC, Key, useEffect, useState } from 'react'
import style from './footermenu-title.module.scss'

type FooterMenuData = {
  data: any
}
export const FooterMenu: FC<FooterMenuData> = (props) => {
  const [menuFooterData, setMenuFooterData] = useState([] as any)
  useEffect(() => {
    try {
      init()
    } catch (err) {}
  }, [props.data.menuData])
  const init = async () => {
    const newArr: any[] = []
    if (props.data.menuData) {
      props.data.menuData.forEach((ele: any, _i: any) => {
        if (ele.children) {
          ele.children.forEach((elee: any, _i: any) => {
            newArr.push({
              parent: ele.name,
              name: elee.name,
              children: elee.children,
            })
          })
        }
      })
    }
    setMenuFooterData(newArr)
  }
  // const headerLink = (name: string, id: any) =>
  //   `/c/${String(name).replace(/ /gi, '-')}?id=${id}&page=1&sort=L-H`

  const linkQuery = (path) => {
    if (!!path?.pageCode && !!path?.pageType) {
      return {
        pathname: `/[slug]/page`,
        query: {
          type: path?.pageType.toLowerCase(),
          slug: path?.pageCode,
        },
      }
    } else {
      return '/'
    }
  }
  const headerLink = (data: any, parent: any, name: any) => {
    let redirectObject = !!data?.linkParams?.params
      ? data?.linkParams?.params
      : []
    let objData: any = redirectObject?.find((e) => e.isDefault == true)
    let slug = `categories.lvl2:=[\`${parent} > ${name} > ${data.name}\`]`
    if (objData?.isClassic) {
      switch (objData?.linkType?.toUpperCase()) {
        case 'EXTERNALLINK':
          return objData?.externalUrl
        case 'PAGE':
          switch (objData?.pageType?.toUpperCase()) {
            case 'GENERAL':
              return linkQuery(objData)
            case 'CATEGORY':
              return linkQuery(objData)
            case 'BRAND':
              return linkQuery(objData)
            case 'OFFER':
              return linkQuery(objData)
          }
        default:
          return '/'
      }
    } else {
      return {
        pathname: `/s`,
        query: {
          categories: slug,
          // stock_status: `stock_status:=[\`In Stock\`]`,
        },
      }
    }
  }
  return (
    <div>
      {menuFooterData.map((res: any, i: number) => (
        <div key={i}>
          <div
            className={`grid grid-cols-1 gap-4 ${
              res.children == 0 ? 'hidden' : 'visible'
            }`}
          >
            <div className={`... ${style.footer_menu}`}>
              {res.name}:
              {res.children
                ? res.children.map(
                    (item: { name: any; _id: any }, index: Key) => (
                      <Link
                        key={index}
                        href={headerLink(item, res.parent, res.name)}
                      >
                        <p key={index} className={`${style.nocostemi} `}>
                          {item.name}{' '}
                          <span
                            className={`${
                              index === res.children.length - 1
                                ? 'hidden'
                                : 'visible'
                            }`}
                          >
                            /
                          </span>
                        </p>
                      </Link>
                    )
                  )
                : ''}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

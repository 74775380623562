// @ts-ignore
// @ts-nocheck
'use client'
import { FC } from 'react'
import { useCallback, useEffect, useState, useLayoutEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Row from '../pim-ui/layout/Row'
import Column from '../pim-ui/layout/Column'
import ElementsMap from '../pim-ui/elements'
import { Footer } from '../footer/footer'
import HomeFooter from '../footer/Homefooter/homefooter'
import Cookies from 'js-cookie'
import { StoreService } from '~/services/stores.service'
import { Logger } from '~/util'
import { AuthState } from '~/stores/auth'
import dynamic from 'next/dynamic'

const WhatsappChat: any = dynamic(
  () => import('~/components/ui/whatsapp-chat/whatsapp-chat')
)
const CallingApp: any = dynamic(
  () => import('~/components/ui/calling-app/callingapp-chat')
)

const Mobilehomeui: FC<any> = (props) => {
  const [slicedData, _setSlicedData] = useState(5)
  const [features, setFeatures] = useState<any[]>(
    props?.features?.pageElements?.slice(0, slicedData)
  )
  const [currentOffset, setCurrentOffset] = useState<number>(5)
  const [_loading, setLoading] = useState(false)
  const [footer, setFooter] = useState(false)
  const [showWhatsapp, setShowWhatsapp] = useState(false)
  const [_scrollOn, setScrollOn] = useState(false)
  setTimeout(() => {
    setShowWhatsapp(true)
  }, 5000)
  const authState = AuthState.useContainer()
  const loader = (
    <div className="loader text-center">
      <img
        src="https://img.poorvika.com/common/loader.gif"
        className="m-auto"
        width={40}
        height={40}
        alt="loader"
        placeholder="blur"
      />
    </div>
  )
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.onCloseModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])
  useEffect(() => {
    let user = Cookies.getJSON()['user']
    const pindata: any = localStorage.getItem('myData')
    if (user == undefined && pindata == 'null') {
      StoreService.Allowlocation()
        .then((response) => {
          let data = response.data.postalCode
          localStorage.setItem('myData', data)
          authState.updatePinCode(data)
        })
        .catch((error) => {
          Logger.log(error)
        })
    } else {
      authState.updatePinCode(String(pindata))
    }
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        setScrollOn(true)
      }
    }
  }, [])
  const fetchItems = useCallback(async () => {
    setLoading(true)
    try {
      // let arr1 = []
      // arr1.push(
      //   props?.features?.pageElements?.slice(currentOffset, currentOffset + 3)
      // )
      let showmore = props?.features?.pageElements?.slice(
        currentOffset,
        currentOffset + 3
      )
      //let [data] = arr1
      let arr2 = features.concat(showmore)
      setFeatures(arr2)
      if (currentOffset < props?.features?.pageElements?.length) {
        setCurrentOffset(currentOffset + 3)
      }
      if (arr2.length == props?.features?.pageElements?.length) {
        setFooter(true)
      }
    } finally {
      setLoading(false)
    }
  }, [currentOffset])
  return (
    <div>
      {props.mode && (
        <InfiniteScroll
          pageStart={1}
          loadMore={fetchItems}
          hasMore={currentOffset < props?.features?.pageElements?.length}
          loader={loader}
        >
          {features?.map((element: any, rowIndex: number) => (
            <Row key={rowIndex} deviceMode={props.mode} {...element}>
              {element?.columns?.map((column: any, colIndex: number) => (
                <Column
                  deviceMode={props.mode}
                  key={colIndex}
                  {...column?.props}
                  {...element}
                >
                  {column?.components?.map(
                    (component: any, compIndex: number) => {
                      const Component: any = (ElementsMap as any)[
                        component.name
                      ]
                      let arr = []
                      column?.components[0].props?.slides?.length >= 1 &&
                        component.props.slides.map((slidedata, i: number) => {
                          const condition = slidedata.banner?.isVisible
                          const current = new Date()
                          let startdate = new Date(slidedata?.banner?.startDate)
                          let enddate =
                            slidedata?.banner?.endDate == null
                              ? slidedata?.banner?.endDate
                              : new Date(slidedata?.banner?.endDate)
                          if (condition != undefined) {
                            if (condition == true) {
                              if (current.valueOf() >= startdate?.valueOf()) {
                                if (
                                  current.valueOf() <= enddate?.valueOf() ||
                                  enddate == null
                                ) {
                                  arr.push(
                                    column?.components[0].props?.slides[i]
                                  )
                                } else {
                                }
                              } else {
                              }
                            } else {
                              arr.push(column?.components[0].props?.slides[i])
                            }
                          } else {
                            arr.push(column?.components[0].props?.slides[i])
                          }
                        })
                      return Component ? (
                        <Component
                          deviceMode={props.mode}
                          key={compIndex}
                          index={compIndex}
                          carosuel={arr}
                          {...component?.props}
                          className={`layoutChild_${compIndex}`}
                        />
                      ) : null
                    }
                  )}
                </Column>
              ))}
            </Row>
          ))}
          {footer && (
            <div>
              <Footer />
              <HomeFooter />
            </div>
          )}
        </InfiniteScroll>
      )}
      {showWhatsapp && (
        <>
          <WhatsappChat />
          <CallingApp />
        </>
      )}
    </div>
  )
}

export default Mobilehomeui
